import { createStore } from 'vuex'
import {Session} from '@/utils/storage'
import router from '@/router/index'
export default createStore({
  state: {
    isLogin:Session.get('isLogin')||false,
    userInfo:Session.get('userInfo')||{},
    name:'',
    address:'',
    list:[],
    pathurl:"",
    path:""
  },
  mutations: {
    getWallet(state,data){
      state.name = (data || {}).name
      state.address = (data || {}).address
    },
    getWalletList(state,list){
        state.list = list || []
    },
    getlogin(state,isLogin){
      state.isLogin = isLogin || []
    },
    getpathurl(state,data){
      state.pathurl = data || ''
    },
    getpath(state,data){
      state.path = data || ''
    },
    getUserInfo(state,userInfo){
      state.userInfo = userInfo || {}
    }
  },
  actions: {
    async setWallet({ commit,dispatch }, data:any) {
        commit('getWallet', data);
        Session.set('token','boonook');
        Session.set('addressdata',data.address);
        commit('getlogin', true);
        Session.set('isLogin',true);
        router.replace('/home')
		},

    async setPrivateKey({ commit,dispatch }, data:any) {
      Session.set('privateKey',data.privateKey);
    },

    async setWallethuanle({ commit,dispatch }, data:any) {
      commit('getWallet', data);
      Session.set('token',data.token);
    },

    async setPassword({ commit,dispatch }, data:any) {
      Session.set('password',data.password);
    },

    async setPathUrl({ commit },data) {
       commit('getpathurl', data.data);
		},   

    async setPath({ commit },data) {
      commit('getpath', data.data);
    },    
    async setWalletList({ commit }, data: object) {
      Session.set('addresslist',data)
			commit('getWalletList', data);
		},
  },
  modules: {},
})