
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    loading: Boolean,
    useshow: {
      type: Boolean,
      default: false,
    },
  },
})
