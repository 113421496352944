import { Router } from 'vue-router';
import wallet from "@/store/wallet";
function loginbycode(to, from, next) {
    wallet.dispatch('setPath',{
        data:to.fullPath
    });
    next()
}

export default function hook(router: Router): void {
    router.beforeEach(loginbycode)
}