import { createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import configureRouter from './configureRouter'
import Page from '@/Page.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () =>import(/* webpackChunkName: "login" */ '@/views/login/login.vue'),
    meta: {
      title: {
        zh: "登录",
        en: "login"
      },
      name: 'login'
    }
  },
  {
    path: '/login',
    component: () =>import(/* webpackChunkName: "login" */ '@/views/login/login.vue'),
    meta: {
      title: {
        zh: "登录",
        en: "login"
      },
      name: 'login'
    }
  },
  {
    path: '/metamask',
    component: () =>import(/* webpackChunkName: "metamask" */ '@/views/metamask/index.vue'),
    meta:{
      auth:false,////不登录同样可以访问
      title: {
        zh: "metamask",
        en: "metamask"
      },
      name: 'metamask'
    }
  },
  {
    path:'/page',
    component:Page,
    redirect:'/home',
    name:'Page',
    children:[
      {
        path: '/home',
        name:'home',
        component: () =>import(/* webpackChunkName: "dati" */ '@/views/suocangzhuanqu/index.vue'),
        meta: {
          title: {
            zh: "锁仓专区",
            en: "home"
          },
          name: 'home'
        }
      }
    ]
  },
  {
    path: '/friend',
    name: 'friend',
    component: () => import(/* webpackChunkName: "dati" */ '@/views/friend/index.vue'),
    meta: {
      title: {
        zh: "邀请好友",
        en: "friend"
      },
      name: 'friend'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: () =>import(/* webpackChunkName: "PageNotFound" */ '@/views/PageNotFound/index.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
})

// Router Hooks
configureRouter(router)

export default router
