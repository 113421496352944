
    import { defineComponent,onMounted,reactive,watch} from 'vue';
    import {useRouter,useRoute} from 'vue-router';
    import {useStore} from 'vuex';
    import {Session} from '@/utils/storage'
    import { useI18n } from 'vue-i18n';
    import { Toast,Badge } from 'vant';
    import {formatBankNumber} from '@/utils/tools';
    export default defineComponent({
        components:{
          'van-badge':Badge
        },
        name: "footer",
        setup() {
          let state = reactive({
            show:false,
            address:"00000000000000000000000000000000",
            rgba:1,
            path:'/home'
          })

          const {locale,t} = useI18n({
            inheritLocale: true,
            useScope: 'global'
          })

          onMounted(async()=>{
            const wallet = Session.get('addressdata');
            console.log('wallet111--------',wallet);
            state.address=formatBankNumber(wallet?wallet:state.address,5);
          })

          return {
            state,
            locale,
            t
          }
        }
    })
